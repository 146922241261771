import { combineReducers } from "@reduxjs/toolkit";
import application from "./application";
import csvHeaders from "./csvHeaders";
import filters from "./filters";
import firmInclusions from "./firmInclusions";
import formOptions from "./formOptions";
import userSettings from "./userSettings";
import users from "./users";

export const { setEnvironment, setPageTitle, fetching, fetchingDone, fetchingBackground, fetchingBackgroundDone, fetchingLMS, fetchingLMSDone, setPrivilege, setCloneWorkflowName, resetCloneWorkflowName } = application.actions;
export const { setFilters, setAppliedFilters, resetFilters } = filters.actions;
export const { setUsers } = users.actions;
export const { setMisightUsers } = users.actions;
export const { setOptions } = formOptions.actions;
export const { setExcludeBD, setExcludeRIA, setExcludeFidelity } = firmInclusions.actions;
export const { setCsvHeaders, resetHeaders } = csvHeaders.actions;
export const { setTheme, addGroup, removeGroup } = userSettings.actions;

// Reducers
export const rootReducer = combineReducers({
  application: application.reducer,
  filters: filters.reducer,
  users: users.reducer,
  formOptions: formOptions.reducer,
  firmInclusions: firmInclusions.reducer,
  csvHeaders: csvHeaders.reducer,
  userSettings: userSettings.reducer,
});
