import React, { useEffect } from "react";
import {
  CssBaseline,
  StyledEngineProvider,
} from "@mui/material";
import { configureStore } from "@reduxjs/toolkit";
import { Amplify } from "aws-amplify";
import { signInWithRedirect, signOut, getCurrentUser, fetchAuthSession } from "aws-amplify/auth";
import { createBrowserHistory } from "history";
import { enqueueSnackbar } from "notistack";
import { createRoot } from "react-dom/client";
import { Provider as ReduxProvider } from "react-redux";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { authConfig } from "./lib/cognito/constants";

import {
  heapInit,
  heapMiddleware,
  heapSetGlobals,
  heapSetUser,
} from "./lib/heap";
import {
  SentryErrorBoundary,
  sentryInit,
  SentryReduxEnhancer,
  sentrySetUsername,
} from "./lib/sentry";

import { rootReducer } from "./state/index";
import MainViewDD from "./views/MainViewDD";
import SetupWrapper from "./views/SetupWrapper";

const history = createBrowserHistory();

// Initialize Analytics
sentryInit(history);
heapInit();

// Initialize Amplify
Amplify.configure({ Auth: authConfig });

const store = configureStore({
  reducer: rootReducer,
  enhancers: (getDefaultEnhancers) => getDefaultEnhancers().concat(SentryReduxEnhancer),
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }),
    heapMiddleware,
  ],
});

const App = function App() {
  useEffect(() => {
    let setupLogin = false;
    if (!setupLogin) {
      getCurrentUser()
        .then((user) => {
          if (!user) {
            try {
              signInWithRedirect({
                provider: "Cognito",
              });
            } catch (error) {
              console.error("Error signing in", error);
              enqueueSnackbar("Error signing in", { variant: "error" });
              signOut();
            }
          } else {
            fetchAuthSession().then((session) => {
              // GET USER INFO
              const userPayload = session?.tokens?.idToken?.payload;
              const userInfo = {
                name: userPayload?.name,
                groups: userPayload?.["cognito:groups"],
                email: userPayload?.email,
              };
              // SETUP HEAP AND SENTRY
              heapSetUser(userInfo.email, userInfo);
              heapSetGlobals({});
              sentrySetUsername(userInfo.email);
            });
          }
        })
        .catch((error) => {
          console.error("Error getting current user", error);
          signInWithRedirect({
            provider: "Cognito",
          });
        });
    }
    return () => {
      setupLogin = true;
    };
  }, []);

  return (
    <SentryErrorBoundary>
      <ReduxProvider store={store}>
        <StyledEngineProvider injectFirst>
          <SetupWrapper>
            <CssBaseline />
            <Router history={history}>
              <Routes>
                <Route path="/*" element={<MainViewDD />} />
              </Routes>
            </Router>
          </SetupWrapper>
        </StyledEngineProvider>
      </ReduxProvider>
    </SentryErrorBoundary>
  );
};

createRoot(document.querySelector("#root")).render(<App />);
