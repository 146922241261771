import React, { useEffect, useMemo, useState } from "react";
import { PlaylistAdd as PlaylistAddIcon, AccountTreeOutlined as AccountTreeOutlinedIcon, Close as CloseIcon } from "@mui/icons-material";
import { Box, Card, CardContent, Dialog, DialogContent, DialogTitle, Grid, IconButton, Tab, Tabs } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useParams } from "react-router-dom";
import CircularProgress from "../../../components/Spinner";
import usePageTitle from "../../../hooks/usePageTitle";
import { API } from "../../../utils/api";
import PageNotFound from "../../404";
import CreateWorkflow from "./CreateWorkflow";
import TaskEdit from "./TaskEdit";
import TaskStep1 from "./TaskStep1";
import TaskStep2 from "./TaskStep2";

export default function TaskCreateWizard({ setSubmitMessage, workflowId, taskId, showDialog, setShowDialog }) {
  // --- Task Update ---
  const taskIdParam = useParams();
  const useStyles = makeStyles((theme) => ({
    dialogPaper: {
      width: "90%",
      height: "90%",
      overflowX: "hidden",
      borderRadius: "30px",
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  }));
  const classes = useStyles();
  // --- Selected in step 1 ---
  // Eventually we should save the full objects instead of strings
  // But right now the backend only returns the strings when saving
  const [action, setAction] = useState("preprocessing");
  const [taskType, setTaskType] = useState("preprocessing");
  const [studyType, setStudyType] = useState("ann");
  const [client, setClient] = useState(null);
  const [title, setTitle] = useState("Preprocessing");
  const [workflowName, setWorkflowName] = useState("");
  const [pageTitle, setPageTitle] = useState("");
  // --- Selected and sent in step 2 ---
  const [taskConfig, setTaskConfig] = useState({});
  const [isRequired, setIsRequired] = useState([]);
  const [taskStatus, setTaskStatus] = useState("DRAFT");

  // --- Steps holder
  const [step, setStep] = useState(1);

  // Loading undone by useEffect data fetching
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();

  const [selectedTab, setSelectedTab] = useState("create task");
  const tabContents = useMemo(() => {
    if (selectedTab === "create workflow") {
      return <CreateWorkflow />;
    }
    return (
      <Card style={{ marginBottom: "1em" }}>
        <CardContent>
          <TaskStep1
            step={step}
            setStep={setStep}
            action={action}
            setAction={setAction}
            taskType={taskType}
            setTaskType={setTaskType}
            studyType={studyType}
            setStudyType={setStudyType}
            client={client}
            setClient={setClient}
            setTitle={setTitle}
            isRequired={isRequired}
            setIsRequired={setIsRequired}
          />
        </CardContent>
      </Card>
    );
  }, [step, setStep, selectedTab, setTitle, isRequired, setStudyType, taskType, setTaskType, studyType, action, setAction, client, setClient]);

  // Grab Config on load
  useEffect(() => {
    let taskRequest;
    const taskIdValue = taskId || taskIdParam.taskIdParam;
    const workflowIdParam = workflowId || taskIdParam?.workflowIdParam;
    const configRequest = taskIdValue ? API.request({
      url: `/tasks/${taskIdValue}/config`,
      method: "GET",
    }) : undefined;

    if (taskIdValue) {
      if (workflowIdParam) {
        taskRequest = API.request({
          url: `/workflow-task/${workflowIdParam}/${taskIdValue}`,
          method: "GET",
        });
      } else {
        taskRequest = API.request({
          url: `/tasks/${taskIdValue}`,
          method: "GET",
        });
      }

      Promise.all([taskRequest, configRequest])
        .then(([taskData, configData]) => {
          const taskPayload = taskData.data.task;
          setClient(taskPayload.client);
          setTaskType(taskPayload.taskType);
          setTitle(taskPayload.title);
          setTaskStatus(taskPayload.status);
          if (workflowId) setWorkflowName(taskPayload.workflowName);
          setStudyType(configData.data.payload.study);
          setTaskConfig(configData.data.payload || {});
          setLoading(false);
        })
        .catch((e) => {
          setError(e);
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }, [taskIdParam.taskIdParam, taskIdParam.workflowIdParam, taskId, workflowId]);

  useEffect(() => {
    if (!workflowId) {
      setPageTitle(taskIdParam.taskIdParam ? "Edit Task" : "Create Task");
    } else {
      setPageTitle("Edit Workflow");
    }
  }, [workflowId, taskIdParam.taskIdParam]);
  usePageTitle(pageTitle);
  if (loading) {
    return <CircularProgress size="2em" />;
  }

  if (error) {
    return <div>Error loading the task</div>;
  }

  const displayHeader = (props) => {
    const createDisplayTaskString = () => {
      const checkboxHeaderMapping = {
        ria_only: "RIA",
        reverse: "Remove Tags",
        is_qa_report: "Generate QA Reports",
      };
      /* eslint-disable no-nested-ternary */
      /* eslint-disable valid-typeof */
      return props.length
        ? props.reduce((headerValue, property) => {
          const taskValue = taskConfig[property];
          return `${headerValue}${typeof taskValue === "string"
            ? ` - ${taskValue.toUpperCase()}`
            : typeof taskValue === "boolean" && taskValue
              ? ` - ${checkboxHeaderMapping[property]}`
              : (typeof taskValue === "number" || typeof taskValue === "integer" || typeof taskValue === "object")
                ? ` - ${taskValue}`
                : ""
          }`;
        }, `${title}`)
          .replace(/_/g, " ")
          .trim()
        : undefined;
    };
    return (
      <div>
        {createDisplayTaskString()}
        {" "}
      </div>
    );
  };

  // EDIT VIEW
  if (taskStatus !== "DRAFT") {
    return <PageNotFound />;
  }

  if (workflowId) {
    return (
      <div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Card>
            <CardContent>
              <Dialog
                open={showDialog}
                onClose={() => setShowDialog(false)}
                fullWidth
                maxWidth="xl"
                classes={{
                  paper: classes.dialogPaper,
                }}
              >
                <DialogTitle id="form-dialog-title" style={{ color: "white", backgroundColor: "#30455c" }}>
                  {`Workflow Name ➡ ${workflowName} ➡ Edit Task`}
                  {showDialog ? (
                    <IconButton
                      aria-label="close"
                      className={classes.closeButton}
                      onClick={() => setShowDialog(false)}
                      size="large"
                    >
                      <CloseIcon />
                    </IconButton>
                  ) : null}
                </DialogTitle>
                <DialogContent
                  style={{ maxHeight: "auto" }}
                >
                  <TaskEdit
                    action={action}
                    taskType={taskType}
                    studyType={studyType}
                    client={client}
                    setSubmitMessage={setSubmitMessage}
                    title={title}
                    taskId={taskId}
                    workflowId={workflowId || ""}
                    workflowName={workflowName || ""}
                    taskConfig={taskConfig}
                    setTaskConfig={setTaskConfig}
                    displayHeader={displayHeader}
                    setShowDialog={setShowDialog}
                    setIsRequired={setIsRequired}
                  />
                </DialogContent>
              </Dialog>
            </CardContent>
          </Card>

        </div>
      </div>
    );
  }
  if (taskIdParam.taskIdParam) {
    return (
      <div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Card>
            <CardContent>
              <TaskEdit
                action={action}
                taskType={taskType}
                studyType={studyType}
                client={client}
                setSubmitMessage={setSubmitMessage}
                title={title}
                taskId={taskIdParam.taskIdParam}
                taskConfig={taskConfig}
                setTaskConfig={setTaskConfig}
                displayHeader={displayHeader}
                setShowDialog={setShowDialog}
                setIsRequired={setIsRequired}
              />
            </CardContent>
          </Card>
        </div>
      </div>
    );
  }

  return (
    <div>
      <div style={{ display: "flex", flexDirection: "column" }}>
        {step === 1
          ? (
            <Grid container spacing={2}>
              <Box style={{ width: "100%" }}>
                <Tabs
                  value={selectedTab}
                  onChange={(e, tabIndex) => { setSelectedTab(tabIndex); }}
                  textColor="secondary"
                  indicatorColor="secondary"
                  aria-label="secondary tabs example"
                >
                  <Tab value="create task" label="Create Task" icon={<PlaylistAddIcon />} />
                  <Tab value="create workflow" label="Create Workflow" icon={<AccountTreeOutlinedIcon />} />
                </Tabs>
              </Box>
              <Box style={{ width: "100%", marginLeft: 10, marginTop: 20 }}>
                {tabContents}
              </Box>
            </Grid>
          )
          : (
            <Card>
              <CardContent>
                <TaskStep2
                  step={step}
                  setStep={setStep}
                  action={action}
                  taskType={taskType}
                  studyType={studyType}
                  client={client}
                  setClient={setClient}
                  setSubmitMessage={setSubmitMessage}
                  title={title}
                  displayHeader={displayHeader}
                  taskConfig={taskConfig}
                  setTaskConfig={setTaskConfig}
                  isRequired={isRequired}
                  setIsRequired={setIsRequired}
                />
              </CardContent>
            </Card>
          )}
      </div>
    </div>
  );
}
