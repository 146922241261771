import { createSlice } from "@reduxjs/toolkit";

export default createSlice({
  name: "formOptions",
  initialState: {
    quarters: ["Loading..."],
    productionCycle: ["Loading..."],
    locksANN: ["Loading..."],
    ddTerritoriesVA: ["Loading..."],
    ddTerritoriesFIA: ["Loading..."],
    territoryGroupsANN: ["Loading..."],
    terrAltLabelsANN: ["Loading..."],
    ddChannelsANN: ["Loading..."],
    splitBrokerChannels: ["Loading..."],
    clientProfilesANN: ["Loading..."],
    firmNamesDD: ["Loading..."],
    usernames: ["Loading..."],
  },
  reducers: {
    setOptions: (state, action) => {
      const { option, value } = action.payload;
      state[option] = value;
    },
  },
});
