import { API, CognitoAPI } from "../utils/api";
import { getFromStorage, saveToStorage } from "../utils/localStorage";
import {
  fetching,
  fetchingDone,
  fetchingBackground,
  fetchingBackgroundDone,
  fetchingLMS,
  fetchingLMSDone,
  setMisightUsers,
  setOptions,
} from "./index";

export function getOptionsData() {
  return (dispatch) => {
    // --- QUARTERS ---
    dispatch(fetching({ element: "quarters" }));
    API.request({ url: "/quarters", method: "GET" }).then((response) => {
      const QuartersArr = response.data.map(d => d.value);
      const QuartersArrPlusEmpty = [""].concat(QuartersArr);
      dispatch(setOptions({ option: "quarters", value: QuartersArrPlusEmpty }));
      dispatch(setOptions({ option: "productionCycle", value: QuartersArrPlusEmpty }));
      dispatch(fetchingDone({ element: "quarters" }));
    }).catch((error) => { console.log(error); });

    // Fetching users
    dispatch(fetching({ element: "usernames" }));
    CognitoAPI.request({ url: "/users", method: "GET", params: { next_token: null } })
      .then((response) => {
        const users = response.data.users.map((obj) => ({ ...obj, label: obj.name }));
        dispatch(setOptions({ option: "usernames", value: users }));
        dispatch(fetchingDone({ element: "usernames" }));
      });

    // --- LOCKS ---

    dispatch(fetching({ element: "locksANN" }));
    API.request({ url: "/locks/ann", method: "GET" }).then((response) => {
      const LocksArr = response.data.map(d => d.value);
      const LocksPlusLive = [""].concat(LocksArr);
      dispatch(setOptions({ option: "locksANN", value: LocksPlusLive }));
      dispatch(fetchingDone({ element: "locksANN" }));
    }).catch((error) => { console.log(error); });

    // --- TERRITORIES ---

    dispatch(fetching({ element: "ddTerritoriesVA" }));
    API.request({ url: "/dd-territories/va", method: "GET" }).then((response) => {
      const territoriesArr = response.data.map((d) => d.value);
      const territoriesPlusEmpty = [""].concat(territoriesArr);
      dispatch(setOptions({ option: "ddTerritoriesVA", value: territoriesPlusEmpty }));
      dispatch(fetchingDone({ element: "ddTerritoriesVA" }));
    }).catch((error) => { console.log(error); });

    dispatch(fetching({ element: "ddTerritoriesFIA" }));
    API.request({ url: "/dd-territories/fia", method: "GET" }).then((response) => {
      const territoriesArr = response.data.map((d) => d.value);
      const territoriesPlusEmpty = [""].concat(territoriesArr);
      dispatch(setOptions({ option: "ddTerritoriesFIA", value: territoriesPlusEmpty }));
      dispatch(fetchingDone({ element: "ddTerritoriesFIA" }));
    }).catch((error) => { console.log(error); });

    dispatch(fetching({ element: "territoryGroupsANN" }));
    API.request({ url: "/territory-groups/ann", method: "GET" }).then((response) => {
      const terrGroupsArr = response.data.map((d) => d.value);
      const terrGroupsPlusEmpty = [""].concat(terrGroupsArr);
      const terrAltLabels = ["", "TERRITORY_ID"].concat(terrGroupsArr);
      dispatch(setOptions({ option: "territoryGroupsANN", value: terrGroupsPlusEmpty }));
      dispatch(setOptions({ option: "terrAltLabelsANN", value: terrAltLabels }));
      dispatch(fetchingDone({ element: "territoryGroupsANN" }));
    }).catch((error) => { console.log(error); });

    dispatch(fetching({ element: "ddChannelsANN" }));
    API.request({ url: "/dd-channels/ann", method: "GET" }).then((response) => {
      const channelsArr = response.data.map((d) => d.value);
      const channelsPlusEmpty = [""].concat(channelsArr);
      dispatch(setOptions({ option: "ddChannelsANN", value: channelsPlusEmpty }));
      dispatch(fetchingDone({ element: "ddChannelsANN" }));
    }).catch((error) => { console.log(error); });

    dispatch(fetching({ element: "splitBrokerChannels" }));
    API.request({ url: "/branch/channels", method: "GET" }).then((response) => {
      const uniqueChannels = response.data.rows.map((d) => d.channel);
      dispatch(setOptions({ option: "splitBrokerChannels", value: uniqueChannels }));
      dispatch(fetchingDone({ element: "splitBrokerChannels" }));
    }).catch((error) => { console.log(error); });

    dispatch(fetching({ element: "clients" }));
    API.request({ url: "/clients", method: "GET" }).then((response) => {
      dispatch(setOptions({ option: "clients", value: response.data }));
      dispatch(fetchingDone({ element: "clients" }));
    }).catch((error) => { console.log(error); });

    dispatch(fetching({ element: "misightUsers" }));
    API.request({ url: "/misight/users", method: "GET" }).then((response) => {
      dispatch(setMisightUsers({ users: response.data }));
      dispatch(fetchingDone({ element: "misightUsers" }));
    }).catch((error) => { console.log(error); });

    dispatch(fetching({ element: "clientProfilesANN" }));
    API.request({ url: "/lms/api/v1/ann/client/ann_clientprofile", method: "GET" }).then((response) => {
      dispatch(setOptions({ option: "clientProfilesANN", value: response.data }));
      dispatch(fetchingDone({ element: "clientProfilesANN" }));
    }).catch((error) => { console.log(error); });

    dispatch(fetchingLMS({ element: "clients" }));
    API.request({ url: "/lms/api/v1/client/client", method: "GET" }).then((response) => {
      dispatch(setOptions({ option: "lms_backend_clients", value: response.data }));
      dispatch(fetchingLMSDone({ element: "clients" }));
    }).catch((error) => { console.log(error); });

    let firmNamesDD = getFromStorage("firmNamesDD");
    if (firmNamesDD?.length) {
      dispatch(setOptions({ option: "firmNamesDD", value: firmNamesDD }));
      dispatch(fetchingBackgroundDone({ element: "firmNamesDD" }));
    } else {
      firmNamesDD = [];
      const getFirmNamesDD = (pageIdx) => {
        API.request({ url: "/dd-firms", method: "GET", params: { skip: pageIdx, limit: 10000 } })
          .then((response) => {
            if (response?.data?.length > 0) { // check if data array has length > 0
              firmNamesDD = firmNamesDD.concat(response.data); // concatenate the data array
              getFirmNamesDD(pageIdx + 10000);
            } else {
              // no more results, so done fetching
              dispatch(setOptions({ option: "firmNamesDD", value: firmNamesDD }));
              dispatch(fetchingBackgroundDone({ element: "firmNamesDD" }));
              saveToStorage("firmNamesDD", firmNamesDD, 86400000); // 24Hrs
            }
          }).catch((error) => {
            console.log(error);
            // handle the error as appropriate for your application
          });
      };
      dispatch(fetchingBackground({ element: "firmNamesDD" }));
      getFirmNamesDD(0);
    }
  };
}
