import React, { forwardRef, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
} from "@mui/material";
import Alert from "@mui/material/Alert";
import makeStyles from "@mui/styles/makeStyles";

import useSubmitTaskHandler from "../../../hooks/useSubmitTaskHandler";
import { lmsTaskTypes } from "../../tasks/create_wizard/TaskMapping";

const useStyles = makeStyles((theme) => ({
  title: {
    position: "center",
  },
  cancelButton: {
    marginRight: theme.spacing(1),
    color: theme.palette.text.onBackground,
    backgroundColor: theme.palette.primary.light,
  },
  confirmButton: {
    color: theme.palette.text.onBackground,
    backgroundColor: theme.palette.secondary.light,
  },
}));

const Transition = forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

export default function SyncDialog({ open, setOpen, study, setSubmitMessage }) {
  const classes = useStyles();
  const [taskId, setTaskId] = useState("");
  const [taskConfig, setTaskConfig] = useState({ study });
  const syncTaskID = "preprocessing_consortium_sync";
  const syncTask = lmsTaskTypes.find((task) => task.id === syncTaskID);

  const submitHandler = useSubmitTaskHandler({
    id: taskId,
    setTaskId,
    client: "ALL_CLIENTS",
    action: syncTask.action,
    studyType: study,
    taskType: syncTask.id,
    taskConfig,
    title: syncTask.label,
    setSubmitMessage,
    redirectToDetails: true,
  });

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => setOpen(false)}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      maxWidth="md"
      fullWidth
    >
      <DialogTitle id="alert-dialog-slide-title" className={classes.title}>
        Sync Data
      </DialogTitle>
      <DialogContent dividers>
        <Alert severity="info" style={{ marginBottom: 20 }}>
          Sync Consortium Exclusions from Aurora to Maria
        </Alert>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            submitHandler(true, { setSubmitMessage }, taskConfig);
            setOpen(false);
          }}
        >
          Sync
        </Button>
      </DialogActions>
    </Dialog>
  );
}
