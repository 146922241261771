import React from "react";
import { useSelector } from "react-redux";
import * as Yup from "yup";

import Lock from "../../../components/input/Formik/Lock";
import TimePeriod from "../../../components/input/Formik/TimePeriod";
import LMSSelect from "../../../components/input/Formik/base/LMSSelect";
import { LMSForm } from "../common/LMSForm";

export const AnnWarningBreakdownSchema = Yup.object().shape({
  multiple_client_ids: Yup.array().min(1).required(),
  time_period: Yup.string().required("Required"),
  start_quarter: Yup.string().required("Required"),
  end_quarter: Yup.string().required("Required"),
  ann_type: Yup.string().required("Required"),
});

export default function AnnWarningBreakdownForm({
  title,
  client,
  studyType,
  step,
  setStep,
  submitHandler,
  taskConfig,
  addToWorkflowRequired,
}) {
  const formOptions = useSelector((state) => state.formOptions);
  const clients = formOptions?.clients?.filter((clientOption) => clientOption.type === "ann");
  return (
    <LMSForm
      client={client}
      studyType={studyType}
      step={step}
      setStep={setStep}
      submitHandler={submitHandler}
      taskConfig={taskConfig}
      validationSchema={AnnWarningBreakdownSchema}
      initialValues={{ multiple_client_ids: [] }}
      title={title}
      requiredInputAdded
      addToWorkflowRequired={addToWorkflowRequired}
    >
      <LMSSelect
        label="Client Selection *"
        name={{ name: "multiple_client_ids", multple: true }}
        optionKey="value"
        multiple
        options={clients}
      />
      <TimePeriod required />
      <Lock studyType="ann" />
      <LMSSelect
        label="Annuity Type *"
        name="ann_type"
        options={[
          { id: "va", label: "VA" },
          { id: "fia", label: "FIA" },
        ]}
      />
    </LMSForm>
  );
}
