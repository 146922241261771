import React, { useState } from "react";
import { Card, CardContent, CardHeader, Divider, Grid } from "@mui/material";
import { Formik } from "formik";
import { useSelector } from "react-redux";

import FormActions from "../../../../components/FormActions";
import FormAlerts from "../../../../components/FormAlerts";
import ClientProfileDropdown from "../../../../components/input/Formik/ClientProfileDropdown";
import LMSCheckbox from "../../../../components/input/Formik/base/LMSCheckBox";
import LMSSelect from "../../../../components/input/Formik/base/LMSSelect";
import { getOptionsByClient } from "../../../../utils/SharedDefinitions";
import { API } from "../../../../utils/api";
import { convertArrayToOptions, snakeCaseObject } from "../../../../utils/dataTransformations";

export const baseURL = "lms/api/v1/dataconfig/misightclientprofilegroupingconfig";

export default function MisightProfile(props) {
  const { clientId, study, id, refreshData, lmsBackendClientId } = props;
  const formOptions = useSelector((state) => state.formOptions);
  const territoryGroups = convertArrayToOptions(getOptionsByClient(formOptions[`territoryGroups${study.toUpperCase()}`], clientId));

  const [editing, setEditing] = useState(false);
  const [saving, setSaving] = useState(false);
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");

  const handleSave = (data) => {
    setSaving(true);
    setError(null);
    setSuccess(null);

    let url = baseURL;
    let method;
    if (id === undefined) {
      method = "POST";
    } else {
      url += `/${id}`;
      method = "PUT";
    }

    return API.request({ url, method, data })
      .then(
        () => {
          setSuccess("-- Successfully edited! --");
          setSaving(false);
          refreshData();
        },
        (e) => {
          setError(JSON.stringify(e.response.data));
          setSaving(false);
          refreshData();
        },
      );
  };

  return (
    <Formik
      initialValues={{ fa: false, ia: false, trva: false, rila: false, iova: false, ...snakeCaseObject(props) }}
      enableReinitialize
    >
      {
        (formik) => (
          <Card>
            <CardHeader title={clientId} />
            <Divider />
            <CardContent>
              <Grid container>
                <Grid item xs={12} md={6} style={{ paddingRight: 10 }}>
                  <ClientProfileDropdown
                    clientId={lmsBackendClientId}
                    studyType={study}
                    showSummary
                    disabled={!editing}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <LMSSelect
                    name="wholesaler_group"
                    options={territoryGroups}
                    label="Wholesaler Group"
                    disabled={!editing}
                  />
                  <LMSSelect
                    name="division_group"
                    options={territoryGroups}
                    label="Division Group"
                    disabled={!editing}
                  />
                  <LMSSelect
                    name="channel_group"
                    options={territoryGroups}
                    label="Channel Group"
                    disabled={!editing}
                  />
                  {study === "ann"
                  && (
                    <>
                      <LMSCheckbox
                        name="fa"
                        label="FA"
                        disabled={!editing}
                      />
                      <LMSCheckbox
                        name="ia"
                        label="IA"
                        disabled={!editing}
                      />
                      <LMSCheckbox
                        name="trva"
                        label="TRVA"
                        disabled={!editing}
                      />
                      <LMSCheckbox
                        name="rila"
                        label="RILA"
                        disabled={!editing}
                      />
                      <LMSCheckbox
                        name="iova"
                        label="IOVA"
                        disabled={!editing}
                      />
                      <LMSCheckbox
                        name="indirect_sales"
                        label="Include Indirect Sales"
                        disabled={!editing}
                      />

                    </>
                  )}
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <FormAlerts
              saving={saving}
              success={success}
              error={error}
              setSuccess={setSuccess}
              setError={setError}
            />
            <FormActions
              editing={editing}
              setEditing={setEditing}
              handleCancel={() => formik.resetForm()}
              handleSave={() => handleSave(formik.values)}
              isValid={formik.touched && (!formik.errors || Object.keys(formik.errors).length === 0)}
            />
          </Card>
        )
      }
    </Formik>
  );
}
