import React, { forwardRef, useMemo, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Tooltip,
  Alert,
  Box,
} from "@mui/material";
import Slide from "@mui/material/Slide";
import CSVUpload from "../../../components/input/CSVUpload";
import useSubmitTaskHandler from "../../../hooks/useSubmitTaskHandler";
import { lmsTaskTypes } from "../../tasks/create_wizard/TaskMapping";

const Transition = forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

export default function ConsortiumBulkUploadDialog({
  open,
  setOpen,
  study,
  direction,
  level,
  setSubmitMessage,
}) {
  const [csv, setCsv] = useState({});
  const [taskId, setTaskId] = useState("");
  const [bulktype, setbulkType] = useState("replace");

  const bulkUploadTaskID = "preprocessing_dd_bulk_upload_consortium_exclusions";
  const bulkUploadTask = lmsTaskTypes.find(
    (task) => task.id === bulkUploadTaskID,
  );

  const handlebulkchange = (event) => {
    setbulkType(event.target.value);
  };

  const taskConfig = {
    study,
    direction,
    level,
    bulktype,
  };

  const submitHandler = useSubmitTaskHandler({
    id: taskId,
    setTaskId,
    client: "ALL_CLIENTS",
    action: bulkUploadTask.action,
    studyType: study,
    taskType: bulkUploadTask.id,
    taskConfig,
    title: bulkUploadTask.label,
    csv,
    setSubmitMessage,
    redirectToDetails: true,
  });

  const supportedFileTypes = useMemo(() => ["xlsx", "xls", "txt", "csv"], []);
  const fileTypeValidated = useMemo(() => {
    const fileExtention = csv.attachment?.name.split(".").pop();
    return supportedFileTypes.includes(fileExtention);
  }, [csv?.attachment, supportedFileTypes]);

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => setOpen(false)}
      aria-labelledby="alert-dialog-slide-title"
      maxWidth="lg"
      fullWidth
    >
      <DialogTitle id="alert-dialog-slide-title">Bulk Upload</DialogTitle>
      <DialogContent dividers>
        <FormControl component="fieldset" fullWidth>
          <FormLabel component="legend">Choose Bulk Upload Job Type</FormLabel>
          <RadioGroup row value={bulktype} onChange={handlebulkchange}>
            <Tooltip title="Replace the entire table data with new data">
              <FormControlLabel
                value="replace"
                control={<Radio color="primary" />}
                label="Replace"
              />
            </Tooltip>
            <Tooltip title="Append new data to the existing table">
              <FormControlLabel
                value="append"
                control={<Radio color="primary" />}
                label="Append"
              />
            </Tooltip>
          </RadioGroup>
        </FormControl>
        <Box mt={2}>
          <Alert severity="warning">
            {bulktype === "replace"
              ? "This will fully replace the data in the table."
              : "This will append the data to the table."}
          </Alert>
        </Box>
        {csv?.attachment && !fileTypeValidated && (
          <Box mt={2}>
            <Alert severity="error">
              {`File type not supported. Please use one of the following file types: ${supportedFileTypes.join(
                ", ",
              )}`}
            </Alert>
          </Box>
        )}
        <Box mt={3}>
          <CSVUpload
            onChange={(update) => setCsv({ attachment: update.csv })}
            value={{ csv }}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            submitHandler(true, { setSubmitMessage }, taskConfig);
            setOpen(false);
          }}
          disabled={!csv?.attachment || !fileTypeValidated}
        >
          Upload
        </Button>
      </DialogActions>
    </Dialog>
  );
}
