/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useState } from "react";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import { Autocomplete, Button, Checkbox, Snackbar, TextField } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import { useNavigate, useParams } from "react-router-dom";
import useSWR from "swr";
import TableLoadingOverlay from "../../components/MaterialTableLoadingOverlay";
import MaterialTable from "../../components/Table";
import { deleteCommonHeaderById, editCommonHeaderMapping, getCommonHeaderTransformById, postCommonHeader, getStandardHeaders } from "../../utils/api";

export function DisplayCommonHeaderMapping() {
  const { studyFileType, id } = useParams();
  const navigate = useNavigate();
  const study = studyFileType.split(":")[0];
  const headerLookup = {};
  const [data, setData] = useState([]);
  const [isOpen, setIsOpen] = useState({ status: false, message: "", severity: "" });
  const [dropDownData, setDropdownData] = useState([]);
  const { data: headerTransformData, error: headerTransformError, mutate } = useSWR(`/lms/api/v1/transform/common-header-transform/${id}`, () => getCommonHeaderTransformById(id));
  const { data: commonHeaderMapping, error: commonHeaderError } = useSWR(`/lms/api/v1/transform/standard-header/${study}`, () => getStandardHeaders(study));

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  useEffect(() => {
    if (headerTransformData) {
      headerTransformData.sort((a, b) => (a.standard_header_name > b.standard_header_name ? 1 : -1));
      setData(headerTransformData);
    }
    if (commonHeaderMapping) {
      commonHeaderMapping.standard_headers.sort((a, b) => (a.standard_header_name > b.standard_header_name ? 1 : -1));
      setDropdownData(commonHeaderMapping.standard_headers);
    }
  }, [headerTransformData, commonHeaderMapping]);
  /* eslint-disable*/
  if (dropDownData.length > 0) {
    dropDownData.map((row) => headerLookup[row.standard_header_name] = row.standard_header_name?.toString());
  }
  if (headerTransformError) return <div>Error loading Common header transform...</div>;
  if (commonHeaderError) return <div>Error loading Standard Header...</div>;
  const columns = [
    { header: "Header Name", accessorKey: "header_name" },
    {
      header: "Standard Header Name",
      accessorKey: "standard_header_name",
      Edit: ({ row }) => (
        <Autocomplete
          defaultValue={row.original.standard_header_name ?? ""}
          options={Object.values(headerLookup)}
          onChange={(event, newValue) => {
            row._valuesCache.standard_header_name = newValue;
          }}
          renderInput={(params) => <TextField {...params} label="Standard Header" />}
        >
        </Autocomplete>
      )
    },
    {
      header: "Required",
      accessorKey: "is_required",
      Edit: ({ row }) => (
        <Checkbox
          defaultChecked={row.original.is_required}
          onChange={(event) => {
            row._valuesCache.is_required = event.target.checked;
          }}
        >
        </Checkbox>
      ),
      Cell: ({ row }) => (
        row.original.is_required ? <CheckIcon /> : <ClearIcon />
      )
    },
  ];
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setIsOpen((prev) => ({ ...prev, status: false, message: "", severity: "" }));
  };
  return (
    <div>

      {headerTransformData ? (
        <>
          <MaterialTable
            columns={columns}
            data={data}
            title={`Common Header Mapping - ${studyFileType}`}
            options={{
              headerStyle: {
                backgroundColor: "#30455c",
              },
              paging: true,
              pageSize: 20,
              pageSizeOptions: [],
              emptyRowsWhenPaging: false,
            }}
            editable={{
              onRowAdd: async (newData) => {
                const rowToAdd = {
                  study_file_type: parseInt(id, 10),
                  header_name: newData.header_name,
                  standard_header_name: newData.standard_header_name,
                  is_required: newData.is_required === true,
                };
                if (data.filter(row => row.header_name === rowToAdd.header_name).length === 0) {
                  await postCommonHeader(rowToAdd);
                  mutate();
                } else {
                  setIsOpen((prev) => ({
                    ...prev, status: true, message: "Header Mapping already added !", severity: "error",
                  }));
                }
              },
              onRowUpdate: async (newData, oldData) => {
                const rowToUpdate = {
                  study_file_type: oldData.study_file_type,
                  header_name: newData.header_name,
                  standard_header_name: newData.standard_header_name,
                  is_required: newData.is_required === true,
                }
                await editCommonHeaderMapping(oldData.id, rowToUpdate);
                mutate();
              },
              onRowDelete: async (oldData) => {
                await deleteCommonHeaderById(oldData.id);
                mutate();
                setIsOpen((prev) => ({
                  ...prev, status: true, message: "Header Mapping Deleted successfully", severity: "success",
                }));
              },
            }}
          />
          <Button
            style={{ marginTop: "1em" }}
            onClick={() => navigate("/client-profiles", { state: { preprocessingTransformOption: "commonHeaderMapping", selectedTab: "preprocessing" } })}
            variant="contained"
            color="primary">
            Done
          </Button>
        </>
      ) : <TableLoadingOverlay />
      }
      {isOpen.status && (
        <Snackbar
          open={isOpen}
          onClose={() => setIsOpen((prev) => ({ ...prev, status: false }))}
          autoHideDuration={4000}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        >
          <div><Alert onClose={handleClose} severity={isOpen.severity}>
            {isOpen.message}
          </Alert></div>
        </Snackbar>
      )}

    </div>
  );
}
