import React, { useContext } from "react";
import { Typography } from "@mui/material";
import * as Yup from "yup";

import ClientProfileDropdown from "../../../components/input/Formik/ClientProfileDropdown";
import Lock from "../../../components/input/Formik/Lock";
import TimePeriod from "../../../components/input/Formik/TimePeriod";
import LMSTextField from "../../../components/input/Formik/base/LMSTextField";
import { FormContext } from "../common/FormContext";
import { LMSForm } from "../common/LMSForm";

export const AnnMissingZipsSchema = Yup.object().shape({
  time_period: Yup.string().required("Required"),
  start_quarter: Yup.string().required("Required"),
  end_quarter: Yup.string().required("Required"),
  client_profile_id: Yup.number().integer().required("Required"),
});

export function ClientProfileSection() {
  const { clientId, studyType } = useContext(FormContext);

  return (
    <ClientProfileDropdown clientId={clientId} studyType={studyType} />
  );
}

export default function AnnMissingZipsDD({
  title,
  client,
  studyType,
  step,
  setStep,
  submitHandler,
  taskConfig,
  addToWorkflowRequired,
}) {
  return (
    <LMSForm
      client={client}
      studyType={studyType}
      step={step}
      setStep={setStep}
      submitHandler={submitHandler}
      taskConfig={taskConfig}
      validationSchema={AnnMissingZipsSchema}
      title={title}
      requiredInputAdded
      addToWorkflowRequired={addToWorkflowRequired}

    >
      <Typography variant="h4" style={{ backgroundColor: "orange" }}>
        Discovery Data
      </Typography>
      <TimePeriod required />
      <Lock studyType="ann" />
      <ClientProfileSection />
      <LMSTextField
        label="Alternate Client_IDs"
        name="alternate_client_ids"
        helperText="If more than one, separate client_ids by comma"
      />
    </LMSForm>
  );
}
